import * as app from './app';
import * as asset from './asset';
import * as map from './map';
import * as settings from './settings';
import * as ui from './ui';
import * as legs from './legs';

const actions = {
  /** @deprecated use app slice instead */
  app,
  asset,
  map,
  settings,
  ui,
  legs,
};

export default actions;
