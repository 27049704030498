import React from 'react';
import {
  Box,
  IconButton,
  Tooltip,
  SvgIcon,
} from '@mui/material';
import { useTranslations } from 'use-intl';
import { DateTime } from 'luxon';
import { useSetViewport } from 'contexts/viewport/useViewport';
import useTimezone from 'hooks/session/useTimezone';
import { useAppDispatch } from 'store/types';
import { setDay as setDayAction } from 'slices/app.slice';
import { setHighlightedReport } from 'slices/report.slice';

export interface JumpToLatestActivityProps {
  latestPosition?: Pick<Report, 'id' | 'longitude' | 'latitude' | 'received'>
  follow?: boolean
  setFollow: (mapId: string, follow: boolean) => void
  selectedMapId: string
}

/**
 * Renders a "target" icon which, when clicked, jumps the applications
 * `now` value to the most recent positions timestamp for the given asset id
 */
const JumpToLatestActivity = ({
  latestPosition,
  follow = false,
  setFollow,
  selectedMapId,
}: JumpToLatestActivityProps) => {
  const t = useTranslations('contextbox.asset.activity.actionButtons');
  const patchViewport = useSetViewport(selectedMapId);
  const timezone = useTimezone();
  const dispatch = useAppDispatch();
  const setDay = (day: string | null) => dispatch(setDayAction(day));
  // If there's no activity, don't render the jumpToLatestActivity button...
  if (!latestPosition) return null;

  const handleClick = () => {
    if (follow) setFollow(selectedMapId, false);
    patchViewport({ longitude: latestPosition.longitude, latitude: latestPosition.latitude });
    dispatch(setHighlightedReport({ id: latestPosition.id }));

    const now = DateTime.now().setZone(timezone);
    const received = DateTime.fromSeconds(latestPosition.received).setZone(timezone);
    const isReceivedToday = received.hasSame(now, 'day');
    setDay(isReceivedToday ? null : received.toISODate());
  };

  const label = t('jumpToLatestTooltip');

  return (
    <Box>
      <Tooltip title={label}>
        <IconButton size="small" onClick={handleClick} aria-label={label}>
          <SvgIcon viewBox="-4 -2 16 16">
            <path d="M6 4c-1.1 0-2 0.9-2 2s0.9 2 2 2 2-0.9 2-2-0.9-2-2-2z m4.47 1.5a4.5 4.5 0 0 0-3.97-3.97v-1.03h-1v1.03a4.5 4.5 0 0 0-3.97 3.97h-1.03v1h1.03a4.5 4.5 0 0 0 3.97 3.97v1.03h1v-1.03a4.5 4.5 0 0 0 3.97-3.97h1.03v-1h-1.03z m-4.47 4c-1.94 0-3.5-1.56-3.5-3.5s1.56-3.5 3.5-3.5 3.5 1.56 3.5 3.5-1.56 3.5-3.5 3.5z" />
          </SvgIcon>
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default JumpToLatestActivity;
