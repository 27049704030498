import { DateTime } from 'luxon';
import { HttpResponseError, serenityFetch } from 'helpers/api';
import { TripBasic } from './types';

interface ApiTrip {
  id: string
  assetId: number
  // Start time in ISO 8601 format
  startTime: string
  startReason?: string
  // End time in ISO 8601 format
  endTime?: string
  endReason?: string
  // Duration in milliseconds
  duration?: number
  distance?: number
  path?: string
  reports?: TripSlimReport[]
  drops?: ApiDrop[]
  status: TripStatus
}

interface ApiTripSummary {
  assetId: number
  // ISO 8601 format
  date: string
  firstTripStart: string
  lastTripEnd: string
}

interface ApiDrop {
  Id: number
  Type: DropType
  AssetId: number
  // Start time in milliseconds
  StartTime: number
  StartReportId: number
  // End time in milliseconds
  EndTime: number
  EndReportId: number
  // Duration in milliseconds
  DurationMs: number
  SplitDrop: boolean
  SuppressantType: Suppressant
  // Drop volume in litres
  DropVolumeLitres?: number
  // Drop end volume in litres
  EndContainerVolumeLitres?: number
}

export interface TripDeletionRequest {
  assetId: number
  start: number
  end: number
}

export type UserTransitionToAdd = Omit<UserTransition, 'id'>;

export const getTripsForAssets = async (organisationId: string, assetIds: number[], from: number, until: number): Promise<Trip[]> => {
  const params = [
    ['from', DateTime.fromMillis(from).toUTC().toISO()],
    ['until', DateTime.fromMillis(until).toUTC().toISO()],
    ['assets', assetIds.map(a => a.toString(10)).join(',')]
  ];
  const response = await serenityFetch('GET', `/organisations/${organisationId}/trips?${new URLSearchParams(params)}`, null);
  HttpResponseError.detect(response);

  const data = await response.json() as { trips: ApiTrip[] };

  return data.trips.map<Trip>(t => ({
    ...t,
    startTime: DateTime.fromISO(t.startTime, { zone: 'utc' }).toMillis(),
    startReason: t.startReason ?? '',
    endTime: t.endTime ? DateTime.fromISO(t.endTime, { zone: 'utc' }).toMillis() : undefined,
    reports: t.reports ?? [],
    drops: (t.drops ?? []).map<Drop>(item => ({
      id: item.Id,
      type: item.Type,
      assetId: item.AssetId,
      startTime: item.StartTime,
      startReportId: item.StartReportId,
      endReportId: item.EndReportId,
      endTime: item.EndTime,
      duration: item.DurationMs,
      splitDrop: item.SplitDrop,
      suppressant: item.SuppressantType,
      dropVolume: item.DropVolumeLitres,
      endVolume: item.EndContainerVolumeLitres,
    })),
  }));
};

export const getBasicTripsForAssets = async (organisationId: string, assetIds: number[], from: number, until: number): Promise<TripBasic[]> => {
  const params = [
    ['from', DateTime.fromMillis(from).toUTC().toISO()],
    ['until', DateTime.fromMillis(until).toUTC().toISO()],
    ['assets', assetIds.map(a => a.toString(10)).join(',')]
  ];
  const response = await serenityFetch('GET', `/organisations/${organisationId}/trips/basic?${new URLSearchParams(params)}`, null);
  HttpResponseError.detect(response);

  const data = await response.json() as { trips: TripBasic[] };

  return data.trips;
};

export const getTripSummaryForAssets = async (organisationId: string, assetIds: number[], from: number, until: number): Promise<TripSummary[]> => {
  const params = [
    ['from', DateTime.fromMillis(from).toUTC().toISO()],
    ['until', DateTime.fromMillis(until).toUTC().toISO()],
    ['assets', assetIds.map(a => a.toString(10)).join(',')]
  ];
  const response = await serenityFetch('GET', `/organisations/${organisationId}/trips/summary?${new URLSearchParams(params)}`, null);
  HttpResponseError.detect(response);

  const data = await response.json() as { tripSummaries: ApiTripSummary[] };

  return data.tripSummaries.map<TripSummary>(t => ({
    assetId: t.assetId,
    day: DateTime.fromISO(t.date).toMillis(),
    firstStart: DateTime.fromISO(t.firstTripStart).toMillis(),
    lastEnd: DateTime.fromISO(t.lastTripEnd).toMillis()
  }));
};

export const updateTrips = async (organisationId: string, transitions: UserTransitionToAdd[]): Promise<void> => {
  const response = await serenityFetch(
    'PUT',
    `/organisations/${organisationId}/trips`,
    { transitions: transitions.map(t => ({ ...t, reportTime: DateTime.fromMillis(t.reportTime).toUTC().toISO() })) }
  );
  HttpResponseError.detect(response);
};

export const deleteTripsBetween = async (organisationId: string, req: TripDeletionRequest): Promise<void> => {
  const response = await serenityFetch(
    'DELETE',
    `/organisations/${organisationId}/trips`,
    {
      assetId: req.assetId,
      start: DateTime.fromMillis(req.start).toUTC().toISO(),
      end: DateTime.fromMillis(req.end).toUTC().toISO(),
    }
  );
  HttpResponseError.detect(response);
};

interface LimitedSlimTerrainReport extends Omit<TripSlimReport, 'timeOfFix' | 'events' | 'distance' | 'gateway'> {
  // Time in ISO 8601 format
  timeOfFix: string
}
export const getReportsBefore = async (organisationId: string, assetId: number, time: number): Promise<TripSlimReport[]> => {
  const params = [
    ['time', DateTime.fromMillis(time).toUTC().toISO()],
  ];

  const response = await serenityFetch('GET', `/organisations/${organisationId}/trips/reports/${assetId}/before?${new URLSearchParams(params)}`, null);
  HttpResponseError.detect(response);
  const { reports } = await response.json();
  return reports.map((r: LimitedSlimTerrainReport): TripSlimReport => (
    { ...r, timeOfFix: DateTime.fromISO(r.timeOfFix).toMillis(), events: [], distance: 0, gateway: '' }
  ));
};

export const getReportsAfter = async (organisationId: string, assetId: number, time: number): Promise<TripSlimReport[]> => {
  const params = [
    ['time', DateTime.fromMillis(time).toUTC().toISO()],
  ];

  const response = await serenityFetch('GET', `/organisations/${organisationId}/trips/reports/${assetId}/after?${new URLSearchParams(params)}`, null);
  HttpResponseError.detect(response);
  return (await response.json()).reports.map((r: LimitedSlimTerrainReport): TripSlimReport => (
    { ...r, timeOfFix: DateTime.fromISO(r.timeOfFix).toMillis(), events: [], distance: 0, gateway: '' }
  ));
};

export const getSupplementaryData = async (organisationId: string, tripId: string): Promise<SupplementaryData | null> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/trips/${tripId}/supplementary-data`, null);
  const detectedResponse = HttpResponseError.detect(response, true);
  return detectedResponse ? (await response.json()).data : null;
};

export const getSupplementaryDataFields = async (organisationId: string): Promise<SupplementaryDataFieldType[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/supplementary-data-fields`, null);
  HttpResponseError.detect(response);
  return (await response.json()).types;
};

export const updateSupplementaryDataForTrip = async (organisationId: string, tripId: string, fields: SupplementaryDataEvent[]): Promise<void> => {
  const response = await serenityFetch('PATCH', `/organisations/${organisationId}/trips/${tripId}/supplementary-data`, { fields });
  HttpResponseError.detect(response, true);
};

export const approveTrip = async (organisationId: string, tripId: string): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/trips/${tripId}/status`, { status: 'Approved' });
  HttpResponseError.detect(response, true);
};
