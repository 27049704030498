import { useContext, useMemo } from 'react';
import { FeatureAssetsContext, FeatureAssetsContextValue } from 'contexts/featureAssets/featureAssetsContext';
import { FeatureAssetsResult, getFeatureAssets } from 'contexts/featureAssets/useFeatureAssets';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useFeatures from 'hooks/features/useFeatures';
import { OrganisationFeature } from 'apis/rest/features/types';

export interface AllFeatureAssetsResult {
  isLoading: boolean
  features: FeatureAsset[]
  getFeature: (key: string) => FeatureAssetsResult
}

export interface FeatureAsset {
  name: string
  properties: FeatureAssetsResult
}

const FEATURE_LOADING: FeatureAsset = {
  name: 'FEATURE_LOADING',
  properties: {
    isLoading: true,
    all: false,
    some: false,
    assetIds: [],
    completeAssetGroups: [],
    partialAssetGroups: [],
    hasAssetId: assetId => false
  }
};

const NONE: FeatureAssetsResult = {
  isLoading: false,
  all: false,
  some: false,
  assetIds: [],
  completeAssetGroups: [],
  partialAssetGroups: [],
  hasAssetId: assetId => false
};

const ALL: FeatureAssetsResult = {
  isLoading: false,
  all: true,
  some: true,
  assetIds: [],
  completeAssetGroups: [],
  partialAssetGroups: [],
  hasAssetId: assetId => true
};

const LOADING: AllFeatureAssetsResult = {
  isLoading: true,
  features: [],
  getFeature: (key: string) => FEATURE_LOADING.properties,
};

// TODO: Remove toggleResult and getToggleResult when featureModules flag is decommissioned
function getToggleResult(flag?: boolean, featureNames?: OrganisationFeature[]) {
  // if the flag is on -> then return undefined
  if (flag) return undefined;

  // if the flag is undefined, we are loading -> return loading
  if (flag === undefined) return LOADING;

  // otherwise, we are using the old feature toggle stuff, build the result using the features
  const result = {
    isLoading: false,
    features: featureNames?.map(feature => ({
      name: feature.featureKey,
      properties: ALL,
    })) ?? [FEATURE_LOADING],
    getFeature: (key: string) => {
      if (featureNames === undefined) {
        return FEATURE_LOADING.properties;
      }
      return result.features.find(feature => feature.name === key)?.properties ?? NONE;
    }
  };

  return result;
}

export const getAllFeatureAssets = (context: FeatureAssetsContextValue, featureModules?: boolean, features?: OrganisationFeature[]): AllFeatureAssetsResult => {
  // TODO: Remove toggleResult and getToggleResult when featureModules flag is decommissioned
  const toggleResult = getToggleResult(featureModules, features);
  if (toggleResult !== undefined) {
    return toggleResult;
  }

  if (context.isLoading) {
    return LOADING;
  }

  const rawFeatures = context.data.flatMap(module => module.features.flatMap<FeatureAsset>(feature => {
    if (module.allowAllAssets) {
      return {
        name: feature,
        properties: ALL
      };
    }

    const assetGroups = module.assetGroups?.reduce<{
        completeAssetGroups: number[],
        partialAssetGroups: number[]
      }>((acc, group) => {
        if (group.isComplete) {
          acc.completeAssetGroups.push(group.assetGroupId);
        } else {
          acc.partialAssetGroups.push(group.assetGroupId);
        }
        return acc;
      }, { completeAssetGroups: [], partialAssetGroups: [] });

    const result = {
      name: feature,
      properties: {
        isLoading: false,
        all: false,
        some: true,
        assetIds: module?.assetIds ?? [],
        completeAssetGroups: assetGroups?.completeAssetGroups ?? [],
        partialAssetGroups: assetGroups?.partialAssetGroups ?? [],
        hasAssetId: (assetId: number): boolean => (result.properties.all || result.properties.assetIds.includes(assetId))
      }
    };

    return result;
  }));

  // consolidate individual features
  const uniqueFeatures = rawFeatures.reduce<{name: string, all: boolean, assetIds: Set<number>, completeAssetGroups: Set<number>, partialAssetGroups: Set<number>}[]>((acc, feature) => {
    // check to see if the name exists in the current list of objects
    const foundIndex = acc?.findIndex(f => f.name === feature.name) ?? -1;
    if (foundIndex > -1) {
      feature.properties.assetIds.forEach(id => acc[foundIndex].assetIds.add(id));
      acc[foundIndex].all = acc[foundIndex].all || feature.properties.all;
      feature.properties.completeAssetGroups.forEach(g => {
        // if complete group is found in the partial group, delete it
        acc[foundIndex].completeAssetGroups.add(g);
        if (acc[foundIndex].partialAssetGroups.has(g)) acc[foundIndex].partialAssetGroups.delete(g);
      });
      feature.properties.partialAssetGroups.forEach(g => {
        // if the partial group is found in the complete groups, don't add it
        if (acc[foundIndex].completeAssetGroups.has(g)) return;
        acc[foundIndex].partialAssetGroups.add(g);
      });
      return acc;
    }

    acc.push({
      name: feature.name,
      all: feature.properties.all,
      assetIds: new Set(feature.properties.assetIds),
      completeAssetGroups: new Set(feature.properties.completeAssetGroups),
      partialAssetGroups: new Set(feature.properties.partialAssetGroups),
    });
    return acc;
  }, []);

  // populates feature list with toggleResult if conditions permit, otherwise populates as normal
  const result: AllFeatureAssetsResult = {
    isLoading: false,
    features: uniqueFeatures.map(feature => ({
      name: feature.name,
      properties: {
        isLoading: false,
        all: feature.all,
        some: true,
        assetIds: [...feature.assetIds],
        completeAssetGroups: [...feature.completeAssetGroups],
        partialAssetGroups: [...feature.partialAssetGroups],
        hasAssetId: (assetId: number): boolean => (feature.all || [...feature.assetIds].includes(assetId))
      }
    })),
    getFeature: (key: string) => result.features.find(feature => feature.name === key)?.properties ?? NONE
  };

  return result;
};

function useAllFeatureAssets(): AllFeatureAssetsResult {
  const contextValue = useContext(FeatureAssetsContext);

  const featureModules = useFeatureFlag('featureModules');
  const features = useFeatures();

  return useMemo(() => getAllFeatureAssets(contextValue, featureModules, features), [contextValue, featureModules, features]);
}

export default useAllFeatureAssets;
