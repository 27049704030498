import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { HttpResponseError } from 'helpers/api';
import {
  getDropsForAssets,
  getDropsForAssetsV1,
} from './requests';
import { dropQueryKeys } from './queryKeys';
import { DropGroupV1, DropSummary } from './types';

type Options<QueryData, SelectedData> = Omit<UseQueryOptions<QueryData, HttpResponseError, SelectedData>, 'queryKey' | 'queryFn'>;

export const useGetDropsForAssetsV1 = <T = DropGroupV1[]>(assets: number[], from?: number, until?: number, options: Options<DropGroupV1[], T> = {}) => {
  const organisationId = useOrganisationId();
  const queryKey = dropQueryKeys.dropsV1ForAssets(organisationId, assets, from, until);
  return useQuery<DropGroupV1[], HttpResponseError, T>(
    queryKey,
    async () => {
      if (!from || !until || assets.length === 0) { return []; }
      return getDropsForAssetsV1(organisationId, assets, from, until);
    },
    {
      ...options,
      enabled: options.enabled !== false && !!(from && until),
    }
  );
};

export const useGetDropsForAssets = <T = DropSummary | undefined>(assets: number[], from?: number, until?: number, options: Options<DropSummary | undefined, T> = {}) => {
  const organisationId = useOrganisationId();
  const queryKey = dropQueryKeys.dropsForAssets(organisationId, assets, from, until);
  return useQuery<DropSummary | undefined, HttpResponseError, T>(
    queryKey,
    async () => {
      if (!from || !until || assets.length === 0) { return undefined; }
      return getDropsForAssets(organisationId, assets, from, until);
    },
    {
      ...options,
      enabled: options.enabled !== false && !!(from && until),
    }
  );
};
