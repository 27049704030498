import React, { useState } from 'react';
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import clsx from 'clsx';
import TimeSince from 'components/shared/timeSince';
import { labelToDisplayLabel } from 'helpers/events';
import TPFormDialog from 'components/tpForms/formModal';
import Icon from 'components/shared/icons/eventIcons';
import { useSetViewport } from 'contexts/viewport/useViewport';
import { useAppDispatch } from 'store/types';
import { useSelector } from 'react-redux';
import { highlightedReportIdSelector, setHighlightedReport, setSelectedReport } from 'slices/report.slice';
import useStyles from '../leg-styles';

export interface SignificantReportProps {
  report: Report;
  selectedMapId: string;
}

const ReportIcon = ({ report }: { report: Report }) => {
  const classes = useStyles();
  const highlightedReportId = useSelector(highlightedReportIdSelector);

  if (report?.events[0]) {
    return (
      <ListItemIcon className={clsx(classes.iconWrapper, { [classes.selected]: highlightedReportId === report.id })}>
        {/* Fragment required because ListItemIcon only allows one child */}
        <>
          <Icon type={report.events[0]} />
          {report.form
            && (
            <Box className={classes.formBadge}>
              <Box className={classes.badgeIconWrapper}>
                <LaunchOutlinedIcon />
              </Box>
            </Box>
            )}
        </>
      </ListItemIcon>
    );
  }
  if (report?.form) {
    return (
      <ListItemIcon className={clsx(classes.iconWrapper, { [classes.selected]: highlightedReportId === report.id })}>
        <Icon type="form" />
      </ListItemIcon>
    );
  }
  return null;
};

const SignificantReport = ({
  report,
  selectedMapId
}: SignificantReportProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [openForm, setOpenForm] = useState<Report | null>(null);
  const patchViewport = useSetViewport(selectedMapId);
  const highlightedReportId = useSelector(highlightedReportIdSelector);

  return (
    <ListItem
      className={classes.listItem}
      onMouseEnter={() => {
        dispatch(setSelectedReport({ mapId: selectedMapId, report }));
      }}
      onMouseLeave={() => {
        dispatch(setSelectedReport({ mapId: selectedMapId, report: null }));
      }}
      onClick={ev => {
        ev.stopPropagation();
        if (report.isValid) patchViewport({ longitude: report.longitude, latitude: report.latitude });
        dispatch(setSelectedReport({ mapId: selectedMapId, report }));
        if (highlightedReportId !== report.id) {
          dispatch(setHighlightedReport({ id: report.id }));
        } else {
          dispatch(setHighlightedReport({ id: null }));
          dispatch(setSelectedReport({ mapId: selectedMapId, report: null }));
        }
      }}
    >
      <ReportIcon report={report} />
      <ListItemText className={classes.listText}>
        <Box className={classes.row}>
          <Box className={classes.reportTitle}>{report.form?.title ?? labelToDisplayLabel(report.events[0] || 'EVT_STANDARD')}</Box>
          <TimeSince time={report.received * 1000} Component={Box} className={classes.reportSince} tooltip />
        </Box>
        {report.form
          ? (
            <Box className={classes.row}>
              <Typography className={classes.formLink} onClick={() => setOpenForm(report)}>Form</Typography>
            </Box>
          )
          : null}
        {openForm
          ? <TPFormDialog report={openForm} onClose={() => setOpenForm(null)} />
          : null}
      </ListItemText>
    </ListItem>
  );
};

export default SignificantReport;
