import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Stack, Typography } from '@mui/material';
import { groupBy } from 'lodash/fp';
import CirclePicker from 'components/shared/circlePicker';
import { styled } from '@mui/material/styles';
import { useTranslations } from 'use-intl';
import { MapTemplate } from 'mapTemplates/reactmapgl/types';
import TracPlusLabsIcon from 'components/shared/labs/Icon';
import useFeatures from 'hooks/features/useFeatures';
import { excludeForwardProps } from 'utils/style';
import mapTemplates from 'mapTemplates';
import useLocaleLanguage from 'hooks/settings/useLocale';
import { MapBaseLayerIds } from 'constants/map';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { getSelectedDay } from 'slices/app.slice';
import useAllFeatureAssets from 'contexts/featureAssets/useAllFeatureAssets';
import { MapSettingsTitle, MapSettingsSection } from '../../settingsDrawer-styles';

const labsProviders = ['NASA GIBS'];

const LabsBox = styled(Box, {
  shouldForwardProp: excludeForwardProps(['labs']),
})<{ labs: boolean }>(({ theme, labs = false }) => `
  background-color: ${labs ? theme.palette.labs.light : 'inherit'};
  padding: ${theme.spacing(0, 3, 0, 3)};
  & h4 {
    ${labs ? `color: ${theme.palette.mode === 'light' ? theme.palette.labs.main : theme.palette.common.black};` : ''}
  }
`);

interface BaseLayerPickerProps {
  mapId: string
  setBaseLayer: (mapId: string, baseLayerId?: MapTemplate) => void
}

const BaseLayerPicker = ({
  mapId,
  setBaseLayer
}: BaseLayerPickerProps) => {
  const t = useTranslations('pages.map.settingsDialog');
  const features = useFeatures();
  const marineMapsFlag = useFeatureFlag('marineMaps');
  const language = useLocaleLanguage();
  const selectedDay = useSelector(getSelectedDay);
  const baseLayerId = useSelector<ReduxState, MapBaseLayerIds>(state => state.map.maps[mapId].baseLayerId);
  const baseLayers = useMemo<MapTemplate[]>(() => (
    Object.values(mapTemplates).map(template => template(language, selectedDay))
  ), [language, selectedDay]);

  // get list of all feature assets
  const featureAssets = useAllFeatureAssets();
  const labsFeature = featureAssets.getFeature('labs');

  // list of features to check via new feature asset
  // TODO: remove and rework when old feature toggles are retired
  const featuresToCheck = ['map.clouds', 'map.marine'];

  const filteredLayers = baseLayers.filter(bl => {
    if (bl.feature === undefined) return true;

    // Only include Labs layers if labs feature is enabled
    if (!labsFeature.some && labsProviders.includes(bl.provider)) return false;

    // Only include OpenSeaMap layer if marineMaps flag is enabled
    if (!marineMapsFlag) return bl.id !== MapBaseLayerIds.OpenSeaMap;

    if (featuresToCheck.includes(bl.feature)) return featureAssets.getFeature(bl.feature).some;

    // TODO: remove when old feature toggles are retired
    return features?.some(f => f.featureKey === bl.feature);
  });
  const providerGrouping = groupBy<MapTemplate>(bl => (labsProviders.includes(bl.provider) ? 'TracPlus Labs' : bl.provider));

  return (
    <MapSettingsSection sx={{ p: 0 }} role="region" aria-labelledby="mapSettingsLayersHeading">
      <MapSettingsTitle id="mapSettingsLayersHeading" sx={{ px: 3, pt: 2 }}>{t('mapLayers')}</MapSettingsTitle>
      <Stack spacing={1}>
        {Object.entries(providerGrouping(filteredLayers))
          .map(([provider, layers]) => {
            const labs = labsProviders.includes(layers[0].provider);
            return (
              <LabsBox labs={labs} key={provider}>
                <Stack direction="row" alignItems="center" spacing={1} mb={-1}>
                  {labs && <TracPlusLabsIcon sx={{ fontSize: '1em' }} />}
                  <Typography
                    key={`${provider}-label`}
                    id={`baserLayerGroupLabel-${provider.replaceAll(' ', '_')}`}
                    variant="overline"
                    fontWeight="bold"
                    component="h4">
                    {provider}
                  </Typography>
                </Stack>
                {/* Disable circle picker and show none selected if the feature is not applicable */}
                <CirclePicker
                  value={baseLayerId}
                  onChange={value => setBaseLayer(mapId, layers.find(layer => layer.id === value))}
                  options={layers.map(baseLayer => ({
                    value: baseLayer.id,
                    label: baseLayer.name,
                    image: <img alt={baseLayer.name} src={baseLayer.thumbnailUrl} />,
                    // TODO: Remove and rework when old feature toggle system is retired
                    disabled: baseLayer?.feature ? !featureAssets.getFeature(baseLayer.feature).some : false,
                  }))}
                  stackProps={{
                    'aria-labelledby': `baserLayerGroupLabel-${provider.replaceAll(' ', '_')}`
                  }}
                  boxProps={{ ml: '0 !important', mr: '6px !important' }}
                />
              </LabsBox>
            );
          })}
      </Stack>
    </MapSettingsSection>
  );
};

export default BaseLayerPicker;
