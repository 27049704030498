import React from 'react';
import Smart from 'hocs/smart';
import JumpView from './jumpToLatest-view';

interface JumpToLatestProps {
  latestPosition?: Pick<Report, 'id' | 'longitude' | 'latitude' | 'received'>
}

export default Smart(
  JumpView,
  state => ({
    follow: state.map.assetsAreBeingFollowedOnMaps[state.map.selectedMapId],
    selectedMapId: state.map.selectedMapId,
  }),
  ac => ({
    setFollow: ac.map.setFollow,
  })
) as React.FC<JumpToLatestProps>;
