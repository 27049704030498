import React from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { fetchOrganisationJobs } from 'apis/rest/jobs';
import { useQuery } from '@tanstack/react-query';
import { Moment } from 'moment';
import { useAssetReports } from 'repositories/reports/hooks';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';
import useTimezone from 'hooks/session/useTimezone';
import useOrganisationId from 'hooks/session/useOrganisationId';
import Details from './details';
import Activity from './activity';
import Jobs from './jobs';
import CollapsePanel from '../modules/collapsePanel';
import useStyles from '../contextbox-styles';

const JobsSection: React.FC<{ asset: AssetWithDevice, now: Moment }> = ({ asset, now }) => {
  const organisationId = useOrganisationId();
  const timezone = useTimezone();
  const jobsEnabled = useFeatureAssets('assets.jobs').some;
  const query = useQuery(['jobs', organisationId, now], () => fetchOrganisationJobs(organisationId, now), {
    select: data => data?.filter(job => job.assetIds.includes(asset?.id?.toString())),
    enabled: jobsEnabled
  });

  if (!query.data) return null;
  return <Jobs jobs={query.data} timezone={timezone} now={now} />;
};

interface AssetContextBoxProps {
  selectedAsset: AssetWithDevice
  contextboxOpen: boolean
  now: Moment
}

const AssetContextBox: React.FC<AssetContextBoxProps> = ({
  selectedAsset,
  contextboxOpen,
  now,
}) => {
  const classes = useStyles();
  const reports = useAssetReports(selectedAsset?.id);

  if (!selectedAsset) return null;

  return (
    <Box className={clsx(classes.contextboxWrapper, { [classes.collapsed]: contextboxOpen })}>
      <Box className={classes.contextbox}>
        <Box className={classes.contentWrapper}>
          <Details asset={selectedAsset} />
          <JobsSection asset={selectedAsset} now={now} />
          <Activity selectedAsset={selectedAsset} reports={reports} />
        </Box>
      </Box>

      <CollapsePanel />
    </Box>
  );
};

export default AssetContextBox;
