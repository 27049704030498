import { useContext, useMemo } from 'react';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useFeature from 'hooks/features/useFeature';
import { FeatureAssetsContext, type FeatureAssetsContextValue } from './featureAssetsContext';

export interface FeatureAssetsResult {
  isLoading: boolean
  all: boolean
  some: boolean
  assetIds: number[]
  completeAssetGroups: number[]
  partialAssetGroups: number[]
  hasAssetId: (assetId: number) => boolean
}

const LOADING: FeatureAssetsResult = {
  isLoading: true,
  all: false,
  some: false,
  assetIds: [],
  completeAssetGroups: [],
  partialAssetGroups: [],
  hasAssetId: assetId => false
};

const ALL: FeatureAssetsResult = {
  isLoading: false,
  all: true,
  some: true,
  assetIds: [],
  completeAssetGroups: [],
  partialAssetGroups: [],
  hasAssetId: assetId => true
};

const NONE: FeatureAssetsResult = {
  isLoading: false,
  all: false,
  some: false,
  assetIds: [],
  completeAssetGroups: [],
  partialAssetGroups: [],
  hasAssetId: assetId => false
};

export function getFeatureAssets(value: FeatureAssetsContextValue, feature: string) {
  if (value.isLoading) return LOADING;

  const modules = value.data.filter(module => module.features.includes(feature));

  const all = modules.some(module => module.allowAllAssets);
  if (all) return ALL;
  if (!modules.length) return NONE;

  const ids = modules.reduce<{ assets: Set<number>, completeAssetGroups: Set<number>, partialAssetGroups: Set<number> }>((acc, module) => {
    module.assetIds?.forEach(id => acc.assets.add(id));
    module.assetGroups?.forEach(g => {
      if (acc.completeAssetGroups.has(g.assetGroupId)) return;
      if (g.isComplete) {
        acc.completeAssetGroups.add(g.assetGroupId);
        acc.partialAssetGroups.delete(g.assetGroupId);
      } else {
        acc.partialAssetGroups.add(g.assetGroupId);
      }
    });
    return acc;
  }, { assets: new Set<number>(), completeAssetGroups: new Set<number>(), partialAssetGroups: new Set<number>() });

  const result: FeatureAssetsResult = {
    isLoading: false,
    all: false,
    some: true,
    assetIds: [...ids.assets],
    completeAssetGroups: [...ids.completeAssetGroups],
    partialAssetGroups: [...ids.partialAssetGroups],
    hasAssetId: (assetId: number): boolean => (result.all || result.assetIds.includes(assetId))
  };

  return result;
}

function getToggleResult(flag: boolean | undefined, toggle: boolean | undefined) {
  if (flag === undefined) return LOADING;
  if (!flag && toggle === undefined) return LOADING;
  if (!flag) return toggle ? ALL : NONE;
  return undefined;
}

function useFeatureAssets(feature: string): FeatureAssetsResult {
  const contextValue = useContext(FeatureAssetsContext);

  // TODO: remove toggleResult when the featureModules flag is decommissioned
  const toggleResult = getToggleResult(
    useFeatureFlag('featureModules'),
    useFeature(feature),
  );

  return useMemo<FeatureAssetsResult>(
    () => toggleResult ?? getFeatureAssets(contextValue, feature),
    [contextValue, feature, toggleResult],
  );
}

export default useFeatureAssets;
