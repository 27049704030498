import React, {
  useEffect,
  useMemo,
  useRef, useState,
} from 'react';
import {
  Box, Typography, Stack
} from '@mui/material';
import {
  AutoSizer, Column, defaultTableRowRenderer, Table, TableHeaderProps, SortDirection, SortDirectionType
} from 'react-virtualized';
import { GetApp } from '@mui/icons-material';
import {
  altitude, bearing, calculateDistance, coordinate, distance, speed
} from 'helpers/unitsOfMeasure';
import { useSelector } from 'react-redux';
import { Settings } from 'reducers/settings';
import { ClassNameMap } from '@mui/styles';
import { useTranslations } from 'use-intl';
import clsx from 'clsx';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import moment from 'moment';
import { useAssetReportsNoInactive } from 'repositories/reports/hooks';
import { labelToDisplayLabel } from 'helpers/events';
import { gatewayToTransport } from 'helpers/transport';
import { useSetViewport } from 'contexts/viewport/useViewport';
import { reportsToKmz } from 'utils/kmz';
import { useSpeedByAsset } from 'hooks/units/useSpeed';
import { supportsBattery } from 'helpers/deviceSupport';
import { useAssetLabel } from 'components/shared/assetLabel';
import useTimezone from 'hooks/session/useTimezone';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';
import MultiSelectFilter from './multiSelectFilter';
import useStyles from './analysisbox-styles';
import TPFormDialog from '../../tpForms/formModal/formModal-view';
import { getSelectedDay } from 'slices/app.slice';
import { setSelectedReport } from 'slices/report.slice';
import { useAppDispatch } from 'store/types';

interface AnalysisboxProps {
  selectedAsset: AssetBasic,
  selectedLeg: Leg | null;
  selectedReport: Report | null;
  selectedMapId: string;
  setFollow: (mapId: string, something: boolean) => void;
  follow: boolean;
  drawerHeight: number
  timeline: boolean
}

const compareTimes = (end: number, start: number): string => (new Date(Math.max(0, (end - start)) * 1000)).toISOString().slice(11, 19);

// const compareTimes = (end: number, start: number): string => (end - start).toString()

type Units = ReduxState['unitSettings']['units'];

const mapToAnalysisBox = (r: Report, units: Units, timezone: string, setOpenForm: (r: Report | null) => void, prev?: Report): AnalysisBoxReport => ({
  id: r.id,
  timestamp: (r.received && timezone)
    ? moment.unix(r.received).tz(timezone).format('D MMM YYYY H:mm:ss z')
    : '--',
  coordinates: (r.latitude && r.longitude && units.coordinate)
    ? coordinate.fromLatLon(r.latitude, r.longitude, units.coordinate)
    : '--',
  altitude: (r.altitude && units.altitude)
    ? altitude.fromSI(r.altitude, units.altitude)
    : '--',
  bearing: (r.course && r.received && units.bearing)
    ? bearing.fromSI(r.course, r.received, r, units.bearing)
    : '--',
  speed: (r.speed && units.speed)
    ? speed.fromKmh(r.speed, units.speed)
    : '--',
  event: r.form
    ? (<Typography onClick={() => { setOpenForm(r); }}><LaunchOutlinedIcon fontSize="small" /> {r.form.title}</Typography>)
    : labelToDisplayLabel(r.events[0]),
  // : 'Standard',
  // text: (textRecipient && textMessage)
  //   ? `${textRecipient}: ${textMessage}`
  //   : '--',
  dop: r.dop
    || '--',
  latency: (r.logged && r.received)
    ? compareTimes(r.logged, r.received)
    : '--',
  elapsed: (prev?.received && r.received)
    ? compareTimes(r.received, prev?.received)
    : '--',
  distance: (prev?.latitude && prev?.longitude && r.latitude && r.longitude)
    ? calculateDistance(prev, r, units.distance)
    : '--',
  gateway: r.gateway
    ? gatewayToTransport(r.gateway)
    : '--',
  metadata: r.metadata
    ? JSON.stringify(r.metadata, null, 1).slice(1, -1).replace(/["|\n]|(\\n)/g, '')
    : '--',
  latitude: r.latitude,
  longitude: r.longitude,
  received: r.received,
  deviceId: r.deviceId,
  isValid: r.isValid,
  battery: r.battery ? `${r.battery}%` : '--'
});

interface SortIndicatorProps {
  sortDirection?: SortDirectionType
}
const SortIndicator = ({ sortDirection }: SortIndicatorProps) => {
  const className = clsx('ReactVirtualized__Table__sortableHeaderIcon', {
    'ReactVirtualized__Table__sortableHeaderIcon--ASC': sortDirection === SortDirection.ASC,
    'ReactVirtualized__Table__sortableHeaderIcon--DESC': sortDirection === SortDirection.DESC
  });

  return (
    <svg
      className={className}
      width={18}
      height={18}
      viewBox="0 0 24 24"
      focusable={false}
      aria-hidden
    >
      <path d="M 20 12 l -1.41 -1.41 L 13 16.17 V 4 h -2 v 12.17 l -5.58 -5.59 L 4 12 l 8 8 l 8 -8 Z" />
    </svg>
  );
};

const HeaderRenderer = ({
  dataKey,
  label,
  sortBy,
  sortDirection
}: TableHeaderProps) => {
  const showSortIndicator = sortBy === dataKey;
  const children = [
    (
      <span
        className="ReactVirtualized__Table__headerTruncatedText"
        key="label"
        title={typeof label === 'string' ? label : undefined}
      >
        {label}
      </span>
    )
  ];

  if (showSortIndicator) {
    children.push(<SortIndicator key="SortIndicator" sortDirection={sortDirection} />);
  }

  return children;
};

const Analysisbox = ({
  selectedAsset,
  selectedLeg,
  selectedMapId,
  selectedReport,
  setFollow,
  follow,
  drawerHeight,
  timeline = false
}: AnalysisboxProps): JSX.Element => {
  const timezone = useTimezone();
  const selectedDay = useSelector(getSelectedDay);
  const now = useMemo(() => (selectedDay ? moment.tz(selectedDay, timezone) : undefined), [selectedDay, timezone]);
  const classes: ClassNameMap = useStyles();
  const t = useTranslations('analysisbox');
  const units = useUnitSettings();
  const {
    altitude: altitudeUnits,
    distance: distanceUnits,
    bearing: bearingUnits,
  } = useUnitSettings();
  const speedUnits = useSpeedByAsset(selectedAsset);
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
  const [selectedFields, setSelectedFields] = useState(['timestamp', 'coordinates', 'altitude', 'bearing', 'speed', 'event', 'latency', 'elapsed', 'distance', 'gateway']);
  const [sort, setSort] = useState({ sortBy: 'timestamp', sortDirection: 'DESC' });
  const [exportSpeedDialOpen, setExportSpeedDialOpen] = useState(false);
  const tableRef = useRef(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [openForm, setOpenForm] = useState<Report | null>(null);
  const assetLabel = useAssetLabel();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (supportsBattery(selectedAsset)) {
      if (!selectedFields.includes('battery')) {
        setSelectedFields([...selectedFields, 'battery']);
      }
    } else if (selectedFields.includes('battery')) {
      setSelectedFields(selectedFields.filter(f => f !== 'battery'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAsset, setSelectedFields]);

  const reportsForAsset = useAssetReportsNoInactive(selectedAsset.id, (now || moment()).tz(timezone).startOf('d').unix());
  const selectedReports = useMemo(() => {
    if (selectedAsset) {
      if (selectedLeg) {
        return reportsForAsset
          .filter(r => r.received >= selectedLeg.start && (!selectedLeg.complete || r.received <= selectedLeg.end));
      }
      return reportsForAsset;
    }
    return [];
  }, [selectedAsset, selectedLeg, reportsForAsset]);

  const analysisBoxReports = useMemo(() => selectedReports
    .slice()
    .reverse()
    .filter(r => (selectedEvents.length ? selectedEvents.includes(labelToDisplayLabel(r.events[0])) : r))
    .map((r, i, a) => mapToAnalysisBox(r, units, timezone, setOpenForm, a[i - 1])), [selectedReports, selectedEvents, units, timezone]);

  const dateSort = (a: AnalysisBoxReport, b: AnalysisBoxReport): number => moment(a.timestamp).tz(timezone).unix() - moment(b.timestamp).tz(timezone).unix();
  const alphabeticalSort = (a: any, b: any): number => a[sort.sortBy]?.localeCompare(b[sort.sortBy]);
  const numericalSort = (a: any, b: any): number => {
    const numericalA = typeof a[sort.sortBy] === 'number' ? a[sort.sortBy] : a[sort.sortBy]?.replace(/\D/g, '');
    const numericalB = typeof b[sort.sortBy] === 'number' ? b[sort.sortBy] : b[sort.sortBy]?.replace(/\D/g, '');
    return numericalA - numericalB;
  };

  const getSortMethod = (): (a: any, b: any) => number => {
    switch (sort.sortBy) {
      case 'timestamp': return dateSort;
      case 'altitude':
      case 'bearing':
      case 'speed':
      case 'dop':
      case 'distance':
        return numericalSort;
      default: return alphabeticalSort;
    }
  };

  const filteredReports = analysisBoxReports.sort(getSortMethod());
  const visibleReports = sort.sortDirection === 'DESC' ? filteredReports.slice().reverse() : filteredReports;
  const visibleReportsWithUnits = visibleReports.map(r => ({
    ...r,
    altitude: typeof r.altitude === 'number' ? altitude.withUnits(r.altitude, altitudeUnits, 0) : r.altitude,
    bearing: typeof r.bearing === 'number' ? bearing.withUnits(r.bearing, bearingUnits, r.received) : r.bearing,
    speed: typeof r.speed === 'number' ? speed.withUnits(r.speed, speedUnits, 1) : r.speed,
    distance: typeof r.distance === 'number' ? distance.withUnits(r.distance, distanceUnits, 2) : r.distance,
  }));

  const reportTitle = selectedLeg
    ? `${selectedLeg.from} - ${selectedLeg.to || ''}`
    : (selectedAsset && !timeline) ? assetLabel(selectedAsset) : '';
  const exportFilename = `TracPlus Export ${reportTitle} ${moment(now ?? new Date()).format('YYYY-MM-DD')}`;
  const uniqueEvents = selectedReports.map(r => labelToDisplayLabel(r.events[0] || 'Standard')).filter((e, i, self) => self.indexOf(e) === i);

  useEffect(() => {
    if (selectedReport) {
      if (selectedReport.id === selectedRow?.id) { return; }
      setSelectedRow(visibleReportsWithUnits.find(r => r.id === selectedReport?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport]);

  const fields = {
    id: { label: 'id', dataKey: 'id', width: () => 0 },
    timestamp: { label: t('timestamp'), dataKey: 'timestamp', width: () => 190 },
    coordinates: { label: t('coordinates'), dataKey: 'coordinates', width: () => 200 },
    altitude: { label: t('altitude'), dataKey: 'altitude', width: () => 90 },
    bearing: { label: t('bearing'), dataKey: 'bearing', width: () => 90 },
    speed: { label: t('speed'), dataKey: 'speed', width: () => 100 },
    event: { label: t('event'), dataKey: 'event', width: () => 100 },
    text: { label: t('text'), dataKey: 'text', width: () => 100 },
    dop: { label: t('dop'), dataKey: 'dop', width: () => 80 },
    latency: { label: t('latency'), dataKey: 'latency', width: () => 100 },
    elapsed: { label: t('elapsed'), dataKey: 'elapsed', width: () => 100 },
    distance: { label: t('distance'), dataKey: 'distance', width: () => 100 },
    gateway: { label: t('gateway'), dataKey: 'gateway', width: () => 100 },
    metadata: { label: t('metadata'), dataKey: 'metadata', width: () => 700 },
    battery: { label: t('battery'), dataKey: 'battery', width: () => 80 }
  };

  const patchViewport = useSetViewport(selectedMapId);

  const getRowRenderer = (props: any): React.ReactNode => defaultTableRowRenderer({
    ...props,
    onRowClick: row => {
      // centering map on past reports for a followed asset causes bad behavior, so unfollow first
      if (follow) setFollow(selectedMapId, false);
      if (row.rowData.isValid) patchViewport({ longitude: row.rowData.longitude, latitude: row.rowData.latitude });
      dispatch(setSelectedReport({ mapId: selectedMapId, report: selectedReports.find(r => r.id === row.rowData.id) ?? null }));
      setSelectedRow(row.rowData);
    },
    onRowMouseOver: row => dispatch(setSelectedReport({ mapId: selectedMapId, report: selectedReports.find(r => r.id === row.rowData.id) ?? null })),
  });

  const handleSort = ({ sortBy, sortDirection }: { sortBy: any, sortDirection: SortDirectionType}): void => setSort({ sortBy, sortDirection });
  const prepareCSVExport = (): string => {
    const rows = visibleReports
      .map(r => {
        const noDashesReport = {};
        Object.keys(r).forEach(k => {
          (noDashesReport as any)[k] = (r as any)[k] === '--' ? '' : (r as any)[k];
        });
        const [latitude, longitude] = r.coordinates === '--' ? ['', ''] : r.coordinates.split(', ');
        return { latitude, longitude, ...noDashesReport };
      })
      // exclude id, coordinates and position columns from the CSV
      .map(({
        id, coordinates, position, ...rest
      }: any) => rest);
    const unitsLookup = {
      timestamp: timezone, altitude: altitudeUnits, bearing: bearing.label(bearingUnits, visibleReports[0]?.received), speed: speedUnits, distance: distanceUnits
    };
    const CSVData = [
      Object.keys(rows[0]).map(value => ((unitsLookup as any)[value] ? `"${value} (${(unitsLookup as any)[value]})"` : `"${value}"`)),
      ...rows
        .map(r => Object.values(r).map(value => `"${value}"`))
    ].map(e => e.join(',')).join('\n');
    return `data:text/csv;charset=utf-8,${CSVData}`;
  };
  const downloadCsvFile = (): void => {
    const encodedUri = encodeURI(prepareCSVExport());
    const element = document.createElement('a');
    element.setAttribute('href', encodedUri);
    element.setAttribute('download', `${exportFilename}.csv`);
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const downloadKmzFile = async (): Promise<void> => {
    const element = document.createElement('a');
    const blob = await reportsToKmz(selectedAsset, selectedReports); // , {type: 'text/plain'});
    element.href = URL.createObjectURL(blob);
    element.download = `${exportFilename}.kmz`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const getRowClassName = (index: { index: number }): string => (selectedRow && visibleReportsWithUnits.findIndex(row => row.id === selectedRow?.id) === index.index ? classes.focusedRow : '');

  return (
    <Box className={classes.tableContainer}>
      <Stack alignItems="start">
        <Typography variant="h2">{reportTitle}</Typography>
        <Typography variant="h5" pb={1}>{visibleReportsWithUnits.length} reports</Typography>
      </Stack>

      <MultiSelectFilter
        options={visibleReportsWithUnits.length ? Object.keys(visibleReportsWithUnits[0])?.filter(key => Object.keys(fields).includes(key)) : []}
        selectedOptions={selectedFields}
        setSelectedOptions={setSelectedFields}
        label={t('fieldFilter.selectColumns')}
        summaryText={t('fieldFilter.summaryText')}
      />
      <MultiSelectFilter
        options={uniqueEvents}
        selectedOptions={selectedEvents}
        setSelectedOptions={setSelectedEvents}
        label={t('eventFilter.filterEvents')}
        summaryText={t('eventFilter.summaryText')}
      />

      <Box className={classes.exampleWrapper}>
        <ClickAwayListener onClickAway={() => setExportSpeedDialOpen(false)}>
          <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<GetApp />}
            onClick={() => setExportSpeedDialOpen(!exportSpeedDialOpen)}
            open={exportSpeedDialOpen}
            direction="down"
          >
            <SpeedDialAction
              key="csv-download"
              icon={<GetApp />}
              tooltipTitle={`${t('export')} CSV`}
              onClick={() => downloadCsvFile()}
              tooltipOpen
            />
            <SpeedDialAction
              key="kmz-download"
              icon={<GetApp />}
              tooltipTitle={`${t('export')} KMZ`}
              onClick={() => downloadKmzFile()}
              tooltipOpen
            />
          </SpeedDial>
        </ClickAwayListener>
      </Box>

      <div className={classes.autosizerWrapper} ref={tableRef}>
        <AutoSizer disableHeight>
          {({ width }) => (
            <Table
              className={classes.reportsTable}
              width={width}
              height={drawerHeight - 80}
              headerHeight={49}
              rowHeight={37}
              rowCount={visibleReportsWithUnits.length}
              rowGetter={({ index }) => visibleReportsWithUnits[index]}
              sort={handleSort}
              sortBy={sort.sortBy}
              sortDirection={sort.sortDirection as SortDirectionType}
              rowRenderer={getRowRenderer}
              rowClassName={index => getRowClassName(index)}
              // onRowClick={this.rowSelection}
              scrollToAlignment="auto"
              scrollToIndex={visibleReportsWithUnits.findIndex(row => row.id === selectedRow?.id)}
              // onRowsRendered={this.rowRendered}
            >
              {selectedFields.map(field => (
                <Column
                  key={(fields as any)[field].dataKey}
                  label={(fields as any)[field].label}
                  dataKey={(fields as any)[field].dataKey}
                  flexGrow={1}
                  width={(fields as any)[field].width(width)}
                  headerRenderer={HeaderRenderer}
                  cellRenderer={({ cellData }) => cellData ?? '--'}
                />
              ))}
            </Table>
          )}
        </AutoSizer>
      </div>
      {openForm
        ? <TPFormDialog report={openForm} onClose={() => setOpenForm(null)} />
        : null}
    </Box>
  );
};

export default Analysisbox;
