/* eslint-disable no-underscore-dangle */
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Map, {
  AttributionControl,
  MapboxEvent,
  MapRef,
  MapStyleDataEvent,
  Projection,
  Source,
  useControl,
  ViewStateChangeEvent,
} from 'react-map-gl';
import { LngLatBounds } from 'mapbox-gl';
import WebMercatorViewport, { FitBoundsOptions } from '@math.gl/web-mercator';
import { PickingInfo } from '@deck.gl/core';
import { MapboxOverlay, MapboxOverlayProps } from '@deck.gl/mapbox';
import { useSelector } from 'react-redux';
import { useLocale, useTranslations } from 'use-intl';
import { project } from 'utils/projection';
import { TRAILS_OPTIONS } from 'constants/trailsoptions';
import {
  useAssetReports,
  useAssetsPositions,
  useLatestPosition,
  useLatestPositionsForAssets,
  useReportsDataRepository,
} from 'repositories/reports/hooks';
import { VIEWS_PER_LAYOUT } from 'constants/maplayouts';
import { MapSettings } from 'reducers/map';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import useFeature from 'hooks/features/useFeature';
import { useDropsOption } from 'hooks/settings/map/useDropsOption';
import { SnackbarSettings } from 'hooks/useSnackbar';
import { useSize } from 'hooks/useSize';
import { useSetViewport, useViewport } from 'contexts/viewport/useViewport';
import { useCursor } from 'contexts/cursor/useCursor';
import { noticeError } from 'helpers/newRelic';
import { useNearestAdsbAircraft } from 'apis/adsb/hooks';
import { useMarkers } from 'apis/rest/markers/hooks';
import { openCreateMarkerDialog, selectIsPlacingMarker } from 'slices/markers.slice';
import { Marker } from 'apis/rest/markers/types';
import type { GeofenceResponseItem } from 'apis/rest/geofence/types';
import { MapTemplate } from 'mapTemplates/reactmapgl/types';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useStaff } from 'hooks/session/useStaff';
import { useAppDispatch } from 'store/types';
import {
  appendMeasurementMarker,
  clearMeasurementMarkers,
  selectMeasurementMarkers,
  selectMeasurementState,
  setMeasurementState,
  updateSelectedAssetId
} from 'slices/map/multiPointMeasurement.slice';
import { selectItem as selectItemAction } from 'slices/app.slice';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';
import useMultiMeasureToolLayers from 'components/maps/reactmapgl/layers/useMultiMeasureToolLayers';
import { MapInstruction } from 'components/shared/mapInstruction/mapInstruction';
import { selectedReportSelector, setSelectedReport } from 'slices/report.slice';
import useStyles from '../map-styles';
import useMeasureToolLayers from './layers/useMeasureToolLayers';
import useDistanceRingLayers from './layers/useDistanceRingLayers';
import useKmlLayers from './layers/useKmlLayers';
import {
  useAssetTrailData,
  useHighlightedTrailLayers,
  useSelectedTrailData,
  useTrailLayers,
} from './layers/useTrailLayers';
import { useDropLayers } from './layers/useDropLayers';
import useReportDotLayers from './layers/useReportDotLayers';
import useEventLayers from './layers/useEventLayers';
import useHighlightedReportLayers from './layers/useHighlightedReportLayers';
import useAssetIconLayers from './layers/useAssetIconLayers';
import WindTrailsLayer from './layers/wind/trails';
import useWindVelocityLayers from './layers/useWindVelocityLayers';
import useMoveMapToSelection from './useMoveMapToSelection';
import { MapboxTrailLayers, useMapboxAssetTrailData } from './layers/mapbox/mapboxTrailLayers';
import { HoveredElement } from './overlays/hoveredElementOverlay';
import Overlays from './overlays';
import useAdsbIconsLayer from './layers/adsbIconsLayer';
import { GeofenceLayers } from './layers/geofenceLayers';
import { FramerateControl } from './controls/framerateControl';
import useVelocityLeadersLayers from './layers/useVelocityLeaders';
import useMarkerIconLayers, { useSingleMarkerIconLayer } from './layers/useMarkerIconLayers';
import useContainmentLineLayers from './layers/useContainmentLineLayers';
import { SunLayer } from './layers/SunLayer';

const noAssets: AssetBasic[] = [];

const getCursor = () => 'default';

const projection: Projection = { name: 'mercator' };

interface TPReactGlMapProps {
  children: ReactNode
  template: MapTemplate
  config: MapSettings
  appSelectedItemId: number | undefined
  thisMapSelectedItemId: number | undefined
  onMouseDown: () => void
  selected: boolean
  follow: boolean
  minZoom: number
  maxZoom: number
  measureToggle: boolean
  distanceRingsToggle: boolean
  velocityLeadersToggle: boolean
  measurementMarker: { lng: number, lat: number }
  assignItemToMap: (id: MapSettings['id'], asset: AssetBasic | undefined) => void
  assignMarkerToAsset: (id: MapSettings['id'], assetId: AssetBasic['id'], coords: { lng: number, lat: number }) => void
  hiddenAssets: AssetBasic[]
  kmlFilenames: string[]
  displaySnackbar: (options: SnackbarSettings) => void
  selectedLeg: Leg | undefined
  contextboxOpen: boolean
  mapLayout: keyof typeof VIEWS_PER_LAYOUT
}

const DeckGLOverlay = (props: MapboxOverlayProps) => {
  const overlay = useControl(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
};

const FramerateOverlay = () => {
  useControl(() => new FramerateControl());
  return null;
};

const TPReactGlMap = ({
  children,
  template,
  config,
  appSelectedItemId,
  thisMapSelectedItemId,
  onMouseDown,
  selected,
  follow,
  minZoom,
  maxZoom,
  measureToggle,
  distanceRingsToggle,
  velocityLeadersToggle,
  measurementMarker,
  assignItemToMap,
  assignMarkerToAsset,
  hiddenAssets,
  kmlFilenames,
  displaySnackbar,
  selectedLeg,
  contextboxOpen,
  mapLayout,
}: TPReactGlMapProps) => {
  const classes = useStyles();
  const t = useTranslations('pages.map');
  const isStaff = useStaff();
  const reportsRepository = useReportsDataRepository();
  const assetsQuery = useGetAssetsList().query;
  const assets = useMemo(() => assetsQuery.data ?? noAssets, [assetsQuery.data]);
  const visibleAssets = useMemo(
    () => {
      const hiddenAssetIds = hiddenAssets.map(a => a.id);
      return assets.filter(a => !hiddenAssetIds.includes(a.id));
    },
    [assets, hiddenAssets],
  );
  const assetSelected = !!thisMapSelectedItemId;
  const selectedReport = useSelector(selectedReportSelector)(config.id);

  // Language controls for mapbox vectors
  // The style doesn't contain language information (just a str) so you must update it like this
  const language = useLocale();
  const mapRef = useRef<MapRef>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [firstSymbolLayer, setFirstSymbolLayer] = useState('');

  const threeDEnabled = useFeatureAssets('map.3d');
  const use3Dmap = threeDEnabled.some && config.threeDEnabled;

  const onLoad = useCallback((e: MapboxEvent) => {
    e.target.setTerrain({ source: 'mapbox-dem', exaggeration: use3Dmap ? 1 : 0 });
    if (e.target.isStyleLoaded()) {
      const firstLayer = e.target.getStyle().layers.find(l => l.type === 'symbol' && l.id.includes('label'))?.id;
      setFirstSymbolLayer(firstLayer ?? '');
    }
  }, [setFirstSymbolLayer, use3Dmap]);

  useEffect(() => {
    const callback = (e?: MapStyleDataEvent) => {
      const map = e?.target ?? mapRef.current?.getMap();
      if (map?.isStyleLoaded() || e) {
        const firstLayer = map?.getStyle().layers.find(l => l.type === 'symbol' && l.id.includes('label'))?.id;
        setFirstSymbolLayer(firstLayer ?? '');
      }

      if (typeof template.template === 'string' && map?.isStyleLoaded()) {
        map.getStyle().layers.forEach(layer => {
          if (layer.type === 'symbol' && layer.layout?.['text-field']) {
            map.setLayoutProperty(layer.id, 'text-field', [
              'coalesce',
              ['get', `name_${language}`],
              ['get', 'name'],
            ]);
          }
        });
      }
    };

    callback();
    mapRef.current?.getMap().once('style.load', callback);
  }, [template, language, setFirstSymbolLayer]);

  useEffect(() => {
    const map = mapRef.current?.getMap();
    // mapbox ignores enable requests if it is already enabled - disable and then enable with options to circumvent
    if (follow) {
      map?.scrollZoom.disable();
      map?.scrollZoom?.enable({ around: 'center' });
    } else {
      map?.scrollZoom.disable();
      map?.scrollZoom?.enable();
    }
  }, [follow]);

  const [cursorPosition, setCursorPosition] = useCursor();
  const viewport = useViewport(config.id);
  const patchViewport = useSetViewport(config.id);

  // this flag is set to ignore viewport zoom changes for asset selections from the map
  // the zoom constantly changing when clicking around the map was confusing and frustrating for users
  const [assetSelectedFromMap, setAssetSelectedFromMap] = useState<number | null>(null);

  const thisMapSelectedAsset = useMemo(() => assets.find(a => a.id === thisMapSelectedItemId), [assets, thisMapSelectedItemId]);

  const latestPositions = useLatestPositionsForAssets(assets, selectedLeg);
  const selectedAssetReports = useAssetReports(thisMapSelectedItemId, selectedLeg);

  // This is needed in several places, so create the viewport up front:
  const wmViewport = useMemo(() => new WebMercatorViewport(viewport), [viewport]);

  const getView = useCallback((bounds: LngLatBounds | null, report: Pick<Report, 'isValid' | 'assetId' | 'latitude' | 'longitude'> | undefined) => {
    if (!report) return;

    if (!report.isValid) {
      displaySnackbar({ id: 'invalidReport', text: 'The position data for this asset is invalid, please contact TracPlus Support for assistance.', type: 'error' });
      return;
    }

    if (bounds && viewport.width) {
      // ignore bound/zoom changes when selecting from map
      if (report.assetId === assetSelectedFromMap) {
        patchViewport({
          latitude: report.latitude,
          longitude: report.longitude,
          zoom: viewport.zoom,
          transitionDuration: 0,
        });
        setAssetSelectedFromMap(null);
        return;
      }

      const extraOpts: Partial<FitBoundsOptions> = { padding: 100 };

      // a bit of a hack to get the context box width to
      // pre-calc for it opening to ensure the bounds are correct.
      if (!contextboxOpen) {
        const contextboxWidth = parseInt(
          getComputedStyle(document.documentElement)
            .getPropertyValue('--contextbox-width')
            .replace('px', ''),
          10
        );
        const numberOfMaps = VIEWS_PER_LAYOUT[mapLayout];
        // never more than two maps wide
        extraOpts.width = Math.floor(wmViewport.width - (contextboxWidth / Math.min(2, numberOfMaps)));
      }

      // eslint-disable-next-line no-underscore-dangle
      try {
        const fitBounds = wmViewport.fitBounds([[bounds._sw.lng, bounds._sw.lat], [bounds._ne.lng, bounds._ne.lat]], extraOpts);
        patchViewport({
          latitude: fitBounds.latitude,
          longitude: fitBounds.longitude,
          zoom: maxZoom ? Math.min(maxZoom, fitBounds.zoom) : fitBounds.zoom,
          bearing: 0,
          pitch: 0,
          transitionDuration: config.animateToSelection ? 2000 : 0
        });
      } catch (error) {
        noticeError(error as Error, {
          bounds: JSON.stringify(bounds),
          contextboxOpen: contextboxOpen.toString(),
          viewport: JSON.stringify(viewport),
          config: JSON.stringify(config),
        });
        patchViewport({
          latitude: report.latitude,
          longitude: report.longitude,
          zoom: viewport.zoom,
          pitch: 0,
          bearing: 0,
          transitionDuration: 0
        });
      }
    } else {
      // there are no positions, possibly due to asset not updating in last X time period, use assets current position instead
      patchViewport({
        latitude: report.latitude,
        longitude: report.longitude,
        zoom: maxZoom - 5,
        transitionDuration: 0,
        bearing: 0,
        pitch: 0
      });
    }
  }, [assetSelectedFromMap, config, contextboxOpen, displaySnackbar, mapLayout, maxZoom, patchViewport, viewport, wmViewport]);

  const selectedItemPosition = useLatestPosition(thisMapSelectedItemId);

  useEffect(() => {
    if (follow && selectedItemPosition) {
      patchViewport({
        latitude: selectedItemPosition.latitude,
        longitude: selectedItemPosition.longitude,
        transitionDuration: 0,
      });
    }
    // by adding viewport.zoom here, we ensure the tracked asset stays in the center of the map when the user zooms in
  }, [config.id, follow, patchViewport, selectedItemPosition]);

  useMoveMapToSelection({
    setView: getView,
    selectedAssetId: thisMapSelectedItemId,
    selectedLeg,
    latestReport: thisMapSelectedItemId
      ? latestPositions[thisMapSelectedItemId]
      : (selectedLeg?.assetId
        ? latestPositions[selectedLeg.assetId]
        : undefined),
    reportsRepository,
  });

  // Measurement marker creation
  const addMeasurementMarker = useCallback(lngLat => {
    if (measureToggle) {
      if (appSelectedItemId) {
        assignMarkerToAsset(config.id, appSelectedItemId, { lng: lngLat[0], lat: lngLat[1] });
      }
    }
  }, [appSelectedItemId, assignMarkerToAsset, config.id, measureToggle]);

  const dispatch = useAppDispatch();
  const isPlacingMarker = useSelector(selectIsPlacingMarker);
  const measurementMarkers = useSelector(selectMeasurementMarkers);
  const measurementState = useSelector(selectMeasurementState);
  const selectItem = (item: AssetBasic | undefined) => dispatch(selectItemAction(item ?? null));

  const featureModules = useFeatureFlag('featureModules');
  const markersToggle = useFeature('map.markers');
  const markersFeatureAssets = useFeatureAssets('map.markers');

  let displayMarkers = false;
  if (featureModules) {
    displayMarkers = config.markersEnabled && markersFeatureAssets.some;
  } else if (featureModules === false) {
    displayMarkers = config.markersEnabled && (markersToggle ?? false);
  }

  const { query: markersQuery } = useMarkers({ enabled: displayMarkers === true });
  const newMarker: Marker = useMemo(() => ({
    id: 0,
    name: '',
    type: 'POI',
    latitude: cursorPosition?.latitude ?? 0,
    longitude: cursorPosition?.longitude ?? 0,
    altitude: 0,
    icon: 'generic',
    colour: '#000',
    clusterId: 'New',
  }), [cursorPosition]);

  // Multipoint measurement tool feature flag
  const multipointMeasurementFeature = useFeatureFlag('multipointMeasurementTool');

  const addMultiMeasurementMarker = useCallback(lngLat => {
    if (!measureToggle) return;
    if (measurementState === 'disabled') return;
    // if (appSelectedItemId === undefined) return; // remove to enable standalone functionality
    // clear upon creation of a new line
    if (measurementState === 'create') {
      dispatch(clearMeasurementMarkers());
    }
    // check for double click
    if (measurementMarkers && measurementMarkers?.at(-1).longitude === lngLat[0] && measurementMarkers?.at(-1).latitude === lngLat[1]) {
      dispatch(setMeasurementState('create'));
    } else {
      // append marker to marker list and change to append mode
      dispatch(appendMeasurementMarker({ latitude: lngLat[1], longitude: lngLat[0] }));
    }
  }, [dispatch, measureToggle, measurementMarkers, measurementState]);

  const handleMapClick = useCallback((info: PickingInfo) => {
    onMouseDown();
    if (multipointMeasurementFeature) {
      addMultiMeasurementMarker(info.coordinate);
    } else {
      addMeasurementMarker(info.coordinate);
    }
    const { x, y } = info;

    // To match clicks with assets we need to project all the asset positions on the map.
    // We also need to do the same to cluster assets when asset clustering is turned on.
    // So we should probably just do it once and re-use the projected positions values.
    const projected = assets.flatMap(a => {
      const recentRep = latestPositions[a.id];
      if (!recentRep) { return []; }
      const { latitude, longitude } = recentRep;
      const pos = project(wmViewport, latitude, longitude);
      const pos2 = project(wmViewport, latitude, longitude, -1);
      const pos3 = project(wmViewport, latitude, longitude, 1);
      return [{
        x: [pos[0], pos2[0], pos3[0]],
        y: [pos[1], pos2[1], pos3[1]],
        assetId: a.id,
      }];
    });

    if (selected) {
      // Check if the click is near any visible assets:
      projected.forEach(p => {
        if (Math.hypot(x - p.x[0], y - p.y[0]) < 48 || Math.hypot(x - p.x[1], y - p.y[1]) < 48 || Math.hypot(x - p.x[2], y - p.y[2]) < 48) {
          // Don't allow selecting hidden assets (aka in hiddenAssets array or trailsOption 2 'show only selected asset & trail' mode)
          // also don't allow selecting assets from the map while in measurement mode
          if (appSelectedItemId !== p.assetId && !hiddenAssets.some(a => a.id === p.assetId) && measurementState === 'disabled') {
            const clickedAsset = assets.find(a => a.id === p?.assetId);
            setAssetSelectedFromMap(clickedAsset?.id ?? null);
            selectItem(clickedAsset);
            assignItemToMap(config.id, clickedAsset);
          }
        }
      });
    }

    if (selectedReport && !thisMapSelectedItemId) {
      const clickedAsset = assets.find(a => a.id === selectedReport.assetId);
      selectItem(clickedAsset);
      assignItemToMap(config.id, clickedAsset);
    }

    if (isPlacingMarker && info.coordinate) {
      dispatch(openCreateMarkerDialog({
        longitude: info.coordinate[0],
        latitude: info.coordinate[1],
      }));
    }
  },
  [
    onMouseDown,
    multipointMeasurementFeature,
    assets,
    selected,
    selectedReport,
    thisMapSelectedItemId,
    isPlacingMarker,
    addMultiMeasurementMarker,
    addMeasurementMarker,
    latestPositions,
    wmViewport,
    appSelectedItemId,
    hiddenAssets,
    measurementState,
    selectItem,
    assignItemToMap,
    config.id,
    dispatch,
  ]
  );

  const dropFeature = useFeatureAssets('map.droplines');
  const dropsOption = useDropsOption(config.id);

  const selectedReportAsset = assets.find(a => a.id === selectedReport?.assetId);

  const onViewStateChange = useCallback((params: ViewStateChangeEvent) => {
    // Only persist relevant properties
    const { bearing, longitude, latitude, zoom, pitch } = params.viewState;
    patchViewport({ bearing, longitude, latitude, zoom, pitch });
  }, [patchViewport]);

  const containerSize = useSize(containerRef);

  useEffect(() => {
    mapRef.current?.resize();
    const { width, height } = containerSize;
    patchViewport({ width, height });
  }, [patchViewport, containerSize]);

  const [hoveredObject, setHoveredObject] = useState<HoveredElement>();
  const [hoveredGeofences, setHoveredGeofences] = useState<GeofenceResponseItem[]>([]);
  const [hoveredMarker, setHoveredMarker] = useState<Marker | undefined>();

  const onHover = useCallback((info: PickingInfo, event) => {
    if (event.type === 'pointermove' && info.coordinate) {
      setCursorPosition({
        latitude: info.coordinate[1],
        longitude: info.coordinate[0],
        x: info.x,
        y: info.y,
      });
    } else {
      setCursorPosition(undefined);
    }

    if (info.picked && info.layer?.id === 'marker-icon-layer' && info.object?.marker?.id) {
      setHoveredMarker(info.object.marker);
    } else {
      setHoveredMarker(undefined);
    }

    if (info.picked && info.object && info.layer?.id === 'report-dot-background-layer') {
      dispatch(setSelectedReport({ mapId: config.id, report: reportsRepository.getReport(info.object.reportId) ?? null }));
    } else {
      dispatch(setSelectedReport({ mapId: config.id, report: null }));
    }

    if (info.object?.properties && info.layer?.id.includes('kml')) {
      setHoveredObject({ x: info.x, y: info.y, properties: info.object.properties, sourceLayerId: info.layer?.id });
    } else {
      setHoveredObject(undefined);
    }
  }, [config.id, reportsRepository, setCursorPosition, dispatch]);

  // On change of selected asset, clear out measurement markers
  useEffect(() => {
    dispatch(updateSelectedAssetId(appSelectedItemId));
  }, [appSelectedItemId, dispatch]);

  // Below we use hooks to create Deck.gl layers
  // These hooks should get their own data internally as much as possible and only minimal shared data be given as arguments
  // They must all take in a 3d value - they have to have a separate ID when they are in 3d or not because they get re-instantiated on the change

  const measureToolLayers = useMeasureToolLayers(
    measureToggle,
    selected,
    selectedItemPosition,
    measurementMarker,
    use3Dmap
  );

  const multiMeasureToolLayers = useMultiMeasureToolLayers(
    measureToggle,
    selected,
    selectedItemPosition,
    use3Dmap
  );

  const measurementToolLayer = useMemo(() => {
    if (multipointMeasurementFeature) {
      return multiMeasureToolLayers;
    }
    return measureToolLayers;
  }, [measureToolLayers, multiMeasureToolLayers, multipointMeasurementFeature]);

  const distanceRingLayers = useDistanceRingLayers(
    assetSelected && distanceRingsToggle,
    wmViewport,
    selectedItemPosition,
    use3Dmap
  );

  const kmlLayers = useKmlLayers(kmlFilenames, config.kmlLabels);

  const assetsWithReportsSelectable = useMemo(() => {
    if (config.trailsOption === TRAILS_OPTIONS.noTrails) {
      return [];
    }
    if (thisMapSelectedAsset) {
      return [thisMapSelectedAsset];
    }
    return config.trailsOption === TRAILS_OPTIONS.allTrailsIcons ? visibleAssets : [];
  }, [thisMapSelectedAsset, config.trailsOption, visibleAssets]);

  const priorityAssetReports = useAssetsPositions(assetsWithReportsSelectable, selectedLeg);

  const assetsWithTrails = useMemo(() => {
    if (config.trailsOption === TRAILS_OPTIONS.noTrails) {
      return [];
    }
    if (thisMapSelectedAsset && config.trailsOption !== TRAILS_OPTIONS.allTrailsIcons) {
      return [thisMapSelectedAsset];
    }
    return config.trailsOption === TRAILS_OPTIONS.allTrailsIcons ? visibleAssets : [];
  }, [visibleAssets, thisMapSelectedAsset, config.trailsOption]);

  const deckNecessaryAssets = useMemo(
    () => (use3Dmap || (config.animateTrails && !config.animateSelectedTrailOnly) ? assetsWithTrails : []),
    [use3Dmap, config.animateTrails, config.animateSelectedTrailOnly, assetsWithTrails]
  );

  const assetsWithIcons = useMemo(() => {
    if (config.trailsOption !== TRAILS_OPTIONS.selectedTrails) {
      return visibleAssets;
    }
    return thisMapSelectedAsset ? [thisMapSelectedAsset] : visibleAssets;
  }, [visibleAssets, thisMapSelectedAsset, config.trailsOption]);

  const allAssetsTrailData = useAssetTrailData(deckNecessaryAssets, selectedLeg, false, use3Dmap);
  const allAssetsMBTrailData = useMapboxAssetTrailData(assetsWithTrails, selectedLeg);
  const selectedTrailData = useSelectedTrailData(config.assetTrailColouring, thisMapSelectedAsset, selectedLeg, use3Dmap);

  const trailLayers = useTrailLayers(
    'all',
    config.trailsOption === TRAILS_OPTIONS.allTrailsIcons,
    allAssetsTrailData,
    config.trailWidth,
    config.animateTrails && !config.animateSelectedTrailOnly,
    viewport.zoom,
    use3Dmap,
    config.showTrailCurtain,
    selectedTrailData.length ? 0.3 : 1,
  );

  const selectedTrailLayers = useTrailLayers(
    'selected',
    config.trailsOption !== TRAILS_OPTIONS.noTrails,
    selectedTrailData,
    config.trailWidth,
    config.animateTrails,
    viewport.zoom,
    use3Dmap,
    config.showTrailCurtain,
    1
  );

  const highlightedTrailLayers = useHighlightedTrailLayers(
    config.trailsOption !== TRAILS_OPTIONS.noTrails,
    assets,
    config.trailWidth,
    use3Dmap
  );

  const dropLayers = useDropLayers(
    dropFeature.some && dropsOption === 'showDropTrails',
    visibleAssets,
    thisMapSelectedItemId,
    selectedLeg,
    config.trailsOption,
    viewport.zoom > 14,
    config.trailWidth
  );

  const containmentLinesEnabled = useFeatureAssets('map.containmentLines').some ?? false;
  const containmentLineLayers = useContainmentLineLayers(
    containmentLinesEnabled,
    config.containmentLinesOption,
    visibleAssets,
    thisMapSelectedItemId,
    selectedLeg,
    config.trailWidth,
    use3Dmap,
  );

  const reportDotLayers = useReportDotLayers(
    config.reportDots && viewport.zoom > config.hideReportDotsAtZoom && !!thisMapSelectedAsset,
    assetsWithReportsSelectable,
    priorityAssetReports,
    config.trailWidth * 1.2,
    config.assetTrailColouring,
    thisMapSelectedAsset?.id,
    viewport.zoom,
    use3Dmap
  );

  const eventLayers = useEventLayers(
    config.trailsOption !== TRAILS_OPTIONS.noTrails,
    assetSelected && thisMapSelectedItemId ? thisMapSelectedAsset : undefined,
    hiddenAssets,
    selectedAssetReports,
    [{
      event: 'DROP',
      enabled: dropFeature.some && dropsOption === 'showDropTrails',
    }, {
      event: 'LOAD_SETTLED',
      enabled: dropFeature.some && dropsOption === 'showDropTrails',
    }, {
      event: 'CONTAINMENT_LINE',
      enabled: containmentLinesEnabled && config.containmentLinesOption !== 'none',
    }],
    use3Dmap
  );

  const highlightedReportLayers = useHighlightedReportLayers(
    selectedReport,
    config.trailWidth * 3.5 + 4,
    selectedReportAsset?.colour ?? undefined,
    use3Dmap
  );

  const assetIconLayers = useAssetIconLayers(
    assetsWithIcons,
    latestPositions,
    appSelectedItemId,
    thisMapSelectedItemId,
    selectedLeg?.complete ?? false,
    hiddenAssets.some(a => a.id === thisMapSelectedItemId),
    config,
    follow,
    use3Dmap
  );

  const markerIconLayers = useMarkerIconLayers(
    markersQuery.data,
    wmViewport,
    hoveredMarker,
    thisMapSelectedItemId,
    displayMarkers,
    use3Dmap,
  );
  const newMarkerIconLayer = useSingleMarkerIconLayer(
    newMarker,
    isPlacingMarker,
  );

  const velocityLeadersLayer = useVelocityLeadersLayers(
    assetSelected && velocityLeadersToggle,
    wmViewport,
    selectedAssetReports[0],
    use3Dmap,
  );

  const adsbEnabled = useFeatureAssets('map.adsb').some;
  const nearestAdsb = useNearestAdsbAircraft(config.id, wmViewport, cursorPosition, config.adsbEnabled && (adsbEnabled ?? false));
  const adsbIconsLayer = useAdsbIconsLayer(
    config.id,
    nearestAdsb,
    (adsbEnabled && config.adsbEnabled) || false,
    use3Dmap
  );
  const weatherAssets = useFeatureAssets('map.weather');
  const weatherFeatureToggle = useFeature('map.weather');

  let weatherEnabled = false;
  if (featureModules) {
    weatherEnabled = !!weatherAssets.some;
  } else if (featureModules === false) {
    weatherEnabled = !!weatherFeatureToggle;
  }

  const geofencesFeature = useFeatureAssets('manage.geofencing');
  const windVelocityLayers = useWindVelocityLayers(!!weatherEnabled && config.windVelocity);
  const cannotPitchMap = (weatherEnabled && config.windTrails) || (!use3Dmap);

  let displayGeofences = false;
  if (featureModules === false) {
    displayGeofences = config.geofencesEnabled;
  } else if (featureModules) {
    displayGeofences = config.geofencesEnabled && geofencesFeature.some;
  }

  return (
    <div className={classes.mapView} ref={containerRef}>
      <Map
        /* eslint-disable-next-line react/jsx-props-no-spreading */// as per ReactMapGl docs
        {...viewport}
        id="main-map"
        onMove={onViewStateChange}
        onLoad={onLoad}
        mapStyle={template.template}
        projection={projection}
        mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
        logoPosition="top-left"
        ref={mapRef}
        maxZoom={maxZoom}
        minZoom={minZoom}
        dragPan={!follow}
        maxPitch={cannotPitchMap ? 0 : 70}
        boxZoom={false}
        doubleClickZoom={false}
        terrain={{ source: 'mapbox-dem', exaggeration: use3Dmap ? 1 : 0 }}
        attributionControl={false}
      >
        <Source
          id="mapbox-dem"
          type="raster-dem"
          url="mapbox://mapbox.mapbox-terrain-dem-v1"
          tileSize={512}
          maxzoom={14}
        />
        <SunLayer beforeId={firstSymbolLayer} dark={template.dark} />
        <MapboxTrailLayers
          data={allAssetsMBTrailData}
          selectedAsset={!!thisMapSelectedAsset}
          beforeId={firstSymbolLayer}
          visible
        />
        <DeckGLOverlay
          _animate
          style={{ position: 'relative', zIndex: '0' }}
          onClick={handleMapClick}
          getCursor={getCursor}
          onHover={onHover}
          onDragStart={onMouseDown}
          pickingRadius={32}
          layers={[
            ...windVelocityLayers,
            ...distanceRingLayers,
            ...kmlLayers,
            ...trailLayers,
            ...selectedTrailLayers,
            ...reportDotLayers,
            ...highlightedTrailLayers,
            ...dropLayers,
            ...containmentLineLayers,
            ...eventLayers,
            ...highlightedReportLayers,
            ...measurementToolLayer,
            ...velocityLeadersLayer,
            ...markerIconLayers,
            newMarkerIconLayer,
            assetIconLayers[0],
            assetIconLayers[1],
          ]}
          interleaved
        />
        <DeckGLOverlay
          _animate
          style={{ position: 'relative', zIndex: '0' }}
          layers={[
            assetIconLayers[2], // label layer
            ...adsbIconsLayer,
          ]}
        />
        {displayGeofences && (
          <GeofenceLayers hoveredGeofenceIds={hoveredGeofences.map(g => g.id)} setHoveredGeofences={setHoveredGeofences} />
        )}
        {weatherEnabled && config.windTrails && <WindTrailsLayer mapTemplate={template.template} />}
        {isStaff && <FramerateOverlay />}
        {template.provider === 'Nautical Charts' ? <AttributionControl customAttribution="Nautical charts provided by OpenSeaMap.org" position="bottom-left" style={{ color: 'black' }} /> : <AttributionControl position="bottom-left" />}
      </Map>
      {children}
      <Overlays
        config={config}
        selectedReport={selectedReport}
        mapIsSelected={selected}
        measureTool={measureToggle}
        selectedAsset={thisMapSelectedAsset}
        hoveredElement={hoveredObject}
        hoveredAdsbAircraft={nearestAdsb}
        hoveredGeofences={hoveredGeofences}
        hoveredMarker={hoveredMarker}
        template={template}
      />
      {measurementState !== 'disabled' && (
        <MapInstruction text={t('measurementTool.instruction')} />
      )}
    </div>
  );
};

export default TPReactGlMap;
